import React from 'react';

const EmotionalGauge = ({ label, value, previousValue, min, max, color, description }) => {
  const percentage = ((value - min) / (max - min)) * 100;
  const previousPercentage = previousValue !== null ? 
    ((previousValue - min) / (max - min)) * 100 : percentage;
  
  const change = previousValue !== null ? value - previousValue : 0;
  
  return (
    <div style={{
      marginBottom: '20px',
      width: '100%'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '2px',
        color: '#E0E0E0'
      }}>
        <span style={{ fontWeight: '500' }}>{label}</span>
        <div>
          <span style={{ 
            color: '#E0E0E0',
            backgroundColor: '#333',
            padding: '2px 8px',
            borderRadius: '10px',
            fontSize: '0.9em'
          }}>
            {value}
          </span>
          {change !== 0 && (
            <span style={{ 
              marginLeft: '5px',
              color: change > 0 ? '#4CAF50' : '#f44336',
              fontSize: '0.9em'
            }}>
              {change > 0 ? '↑' : '↓'} {Math.abs(change).toFixed(1)}
            </span>
          )}
        </div>
      </div>
      <div style={{
        color: '#c59ad1e6',
        fontSize: '0.85em',
        marginBottom: '4px',
      }}>
        {description}
      </div>
      <div style={{
        width: '100%',
        height: '8px',
        backgroundColor: '#333',
        borderRadius: '4px',
        overflow: 'hidden',
        position: 'relative'
      }}>
        {previousValue !== null && previousPercentage !== percentage && (
          <div style={{
            position: 'absolute',
            width: `${previousPercentage}%`,
            height: '100%',
            backgroundColor: color,
            opacity: 0.3
          }} />
        )}
        <div style={{
          width: `${percentage}%`,
          height: '100%',
          backgroundColor: color,
          transition: 'width 0.3s ease-in-out'
        }} />
      </div>
    </div>
  );
};

function EmotionalState({ state, previousState, analysis }) {
  const getStateDescription = (key, value) => {
    const descriptions = {
      valence: value > 0 ? 'Positive Mood' : 'Negative Mood',
      arousal: value > 5 ? 'High Energy' : 'Low Energy',
      dominance: value > 5 ? 'Feeling in Control' : 'Feeling Helpless',
      openness: value > 5 ? 'Very Open' : 'Reserved',
      trust: value > 5 ? 'Trusting' : 'Guarded',
      pain: value > 5 ? 'High Pain' : 'Minimal Pain',
      anxiety: value > 5 ? 'Very Anxious' : 'Calm'
    };
    return descriptions[key] || '';
  };

  return (
    <div style={{
      backgroundColor: '#111',
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
      maxWidth: '600px',
      margin: '20px auto',
      border: '1px solid #333'
    }}>
      <h2 style={{
        marginBottom: '20px',
        color: '#E0E0E0',
        borderBottom: '2px solid #333',
        paddingBottom: '10px'
      }}>
        Persona Emotional State
      </h2>
      
      <div style={{ padding: '10px' }}>
        <EmotionalGauge
          label="Valence"
          value={state.valence}
          previousValue={previousState?.valence}
          min={-5}
          max={5}
          color="#4CAF50"
          description={getStateDescription('valence', state.valence)}
        />
        <EmotionalGauge
          label="Arousal"
          value={state.arousal}
          previousValue={previousState?.arousal}
          min={0}
          max={10}
          color="#2196F3"
          description={getStateDescription('arousal', state.arousal)}
        />
        <EmotionalGauge
          label="Dominance"
          value={state.dominance}
          previousValue={previousState?.dominance}
          min={0}
          max={10}
          color="#9C27B0"
          description={getStateDescription('dominance', state.dominance)}
        />
        <EmotionalGauge
          label="Openness"
          value={state.openness}
          previousValue={previousState?.openness}
          min={0}
          max={10}
          color="#FF9800"
          description={getStateDescription('openness', state.openness)}
        />
        <EmotionalGauge
          label="Trust"
          value={state.trust}
          previousValue={previousState?.trust}
          min={0}
          max={10}
          color="#03A9F4"
          description={getStateDescription('trust', state.trust)}
        />
        <EmotionalGauge
          label="Pain/Discomfort"
          value={state.pain}
          previousValue={previousState?.pain}
          min={0}
          max={10}
          color="#f44336"
          description={getStateDescription('pain', state.pain)}
        />
        <EmotionalGauge
          label="Anxiety"
          value={state.anxiety}
          previousValue={previousState?.anxiety}
          min={0}
          max={10}
          color="#FF5722"
          description={getStateDescription('anxiety', state.anxiety)}
        />
      </div>

      {analysis && (
        <div style={{
          marginTop: '20px',
          padding: '15px',
          backgroundColor: '#1a1a1a',
          borderRadius: '8px',
          fontSize: '0.9em',
          color: '#c59ad1e6',
          borderLeft: '4px solid #3a59b5'
        }}>
          <h3 style={{ 
            marginBottom: '10px', 
            color: '#E0E0E0',
            fontSize: '1em',
            fontWeight: '500'
          }}>
            Emotional Shift Analysis
          </h3>
          <p style={{ margin: 0, lineHeight: '1.4' }}>
            {typeof analysis === 'object' ? analysis.analysis : analysis}
          </p>
        </div>
      )}
    </div>
  );
}

export default EmotionalState; 